//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast,Dialog } from "vant";
export default {
  name: "Home",
  data() {
    return {
      list: [],
      paperId:undefined,
      title:'',
      loading: false,
      finished: false,
    };
  },
  created() {},
  mounted() {
    window.document.title = "门店巡检";
    this.getDefaultPaper()
  },
  activated() {
    window.document.title = "门店巡检";
    this.onLoad()
  },
  methods: {
    getAreaByCode(code){
      let dataMap = {
        "J01":  "加盟东北",
        "J02":  "加盟贵州",
        "J03":  "加盟华北",
        "J04":  "加盟华东",
        "J05":  "加盟华南",
        "J06":  "加盟中南",
        "J07":  "加盟西北",
        "J08":  "加盟西南",
        "J09":  "加盟华中",
        "R07":  "特销",
        "R08":  "电商",
        "R09":  "抖音",
        "R22":  "新零售", 
        "R27":  "互联网定制", 
        "R99":  "品牌",
        "Z01":  "凤凰东北",
        "Z02":  "凤凰华北",
        "Z03":  "凤凰华东",
        "Z04":  "凤凰华南",
        "Z05":  "凤凰其它",
        "Z06":  "凤凰西北",
        "Z07":  "凤凰西南",
        "Z08":  "凤凰中南",
        "Z09":  "凤凰华中",
      }
      return dataMap[code] || ''
    },
    getDefaultPaper(){
       this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + 
          "/store/patrol/paper/defaultPaper",
          {
          "pager": {
            "pageIndex": 0,
            "pageSize": 0,
            "rowsCount": 0,
            "start": 0
          },
          "queryMap": {
          },
          "queryMapObject": {}
        }
        )
        .then((res) => {
          console.log(res.data);
           if (res && res.data && res.data.data && res.data.data.length > 0) {
            this.paperId = res.data.data[0].id
            this.title = res.data.data[0].title
          }
         
        });
    },
    onSubmit() {
      console.log("onSubmit");
      this.$router.push(
        "/stockcheck/detail?code=" +
          this.productCode.toUpperCase() +
          "&shop=" +
          this.currentStore
      );
    },
    onLoad() {
      this.loading = false;
      let postData = {
        pager: {
          pageIndex: 1,
          pageSize: 100,
          rowsCount: 0,
          start: 0,
        },
        queryMap: {
        },
        queryMapObject: {},
      };
      this.axios
        .post(
           "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/group/list",
          postData
        )
        .then((res) => {
          this.finished = true;
          if (res && res.data && res.data.data) {
            console.log(res.data);
            this.list = res.data.data.list;
          }else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        });
    },
    deleteItem(id) {
        Dialog.confirm({
       title: '确认删除？',
        message: '巡检删除后不可恢复',
      })
        .then(() => {
      let postData = {
        id: id,
        isDel: "1",
      };
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/group/edit",
          postData
        )
        .then((res) => {
          if (res.data && res.data.code == 0) {
            Toast("删除成功");
            this.onLoad();

            //  this.list = res.data.data.list;
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        });
        })
          .catch(() => {
            // on cancel
          });
    },
    goPage(url){
      console.log(url);
      this.$router.push(url)
    }
  },
};
